<!-- @format -->

<template>
  <div>
    <div class="filmwelt-footer" :class="$mq">
      <a class="external" href="http://www.filmweltverleih.de/" target="_blank"
        >FILMWELT HOME</a
      >
      <a @click.prevent="emitPressClick" href="#">Presse</a>
      <a
        class="external"
        href="http://www.filmweltverleih.de/page/impressum"
        target="_blank"
        >Impressum</a
      >
      <a
        class="external"
        href="http://www.filmweltverleih.de/page/datenschutz"
        target="_blank"
        >Datenschutz</a
      >
      <a
        class="external"
        v-if="teilnahmebedingung != 'leer'"
        :href="'/' + teilnahmebedingung"
        target="_blank"
        >TEILNAHMEBEDINGUNGEN</a
      >
    </div>
    <div class="filmwelt-cline">
      © 2021 Filmwelt Verleihagentur GmbH. Alle Rechte vorbehalten. made by
      <a
        class="text-white external"
        href="http://www.novagraphix.de"
        target="_blank"
        >novagraphix</a
      >
    </div>
    <PressModal />
  </div>
</template>

<script>
  import PressModal from '@/components/Base/Elements/Modals/Press.vue'
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    components: {
      PressModal,
    },
    props: {
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    methods: {
      emitPressClick() {
        EventBus.$emit('pressmodal')
      },
    },
  }
</script>

<style lang="scss">
  .filmwelt-footer {
    $self: &;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    justify-content: center;
    text-transform: uppercase;
    a {
      color: black;
      &:hover {
        color: $secondary;
      }
      &:not(:last-child):after {
        color: black;
        content: '|';
        padding: 0 5px 0 5px;
      }
    }
    &.sm {
      background-color: white;
      a {
        &:after {
          content: '';
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
    &.md,
    &.lg,
    &.xl,
    &.xxl {
      flex-direction: row;
    }
  }
  .filmwelt-billing {
    width: 100%;
    &.lg,
    &.xl,
    &.xxl {
      margin-bottom: 1.5rem;
      padding: 0 5rem;
    }
    img {
      margin: 0 auto;
      padding: 0 5rem;
      &.sm,
      &.md {
        width: 100%;
        height: auto;
      }
      &.lg,
      &.xl,
      &.xxl {
        width: auto;
      }
    }
  }
  .filmwelt-cline {
    position: relative;
    font-size: 0.7rem;
    color: black;
    padding: 0 1rem;
    a {
      color: black;
      &:hover {
        color: $secondary;
      }
    }
  }
</style>
